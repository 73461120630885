@media only screen and (max-width: 768px) {
    main{
        margin: 25px;
        padding: 10px;
    }
    .desktop-view {
      display: none;
    }
  
    .mobile-view {
      display: block;
    }
  
    .mobile-nav-icon {
      display: block;
      cursor: pointer;
    }
    .mobile-view{
      display: none;
      transition: max-height 0.5s ease-in-out;
      max-height: 0;
      overflow: hidden;
    }
    .mobile-view.open{
      display: block;
      max-height: 500px;
    }
  
    .bar {
      width: 25px;
      height: 3px;
      background-color: #15244d;
      margin: 6px 0;
      transition: 0.8s;
    }
  
    .mobile-nav-icon.open .bar:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .mobile-nav-icon.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .mobile-nav-icon.open .bar:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
    section#about {
      text-align: left;
      margin-bottom: 10px;
      width: 100%;
      padding: 5px;
      background-color:white;
    }
    #about h1 {
      font-size: 28px;
    }
  
    #about p {
      font-size: 14px;
    }
  
    #about-content {
      flex-direction: column;
      align-items: center;
    }
  
    #text {
      width: 100%;
      margin-bottom: 20px;
    }
  
    #banner {
      width: 100%;
    }
  
    #banner img {
      max-width: 100%;
    }
  
    .logo img {
      width: 120px;
      height: auto;
    }
  
    main {
      margin: 21px;
      padding: 20px;
    }
  
    .footer-content {
      flex-direction: column;
      text-align: center;
      padding: 10px;
      padding-right: 0;
      font-size: large;
    }
  
    .footer-content .logo img {
      margin-bottom: 10px;
    }
    .header-content ul{
      display:none;
    }
    .logo{
      margin: auto;
    }
    .card {
      width: 90%; 
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      margin: 12px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease-in-out;
    }
    
    .card img {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #ddd;
    }
    
    .card-info {
      padding: 16px; 
    }
    
    .card h3 {
      margin-bottom: 8px;
      font-size: 1rem; 
    }
    
    .card p {
      font-size: 0.8rem; 
      color: #555;
    }
    
    .product-list {
      display: flex;
      flex-direction: column;
      gap: 20px; 
    }
    
    #contact-wrapper {
      flex-direction: column; 
      gap: 20px;
    }
  
    #contact-info,
    #contact-form {
      max-width: 80%; 
      width: 100%; 
    }
      #welcome-text{
        font-size: 10px;
      }
      .p-button{
        font-size: small;
        width: 100px;
        height: 100px;
      }
      #about-padding{
        padding-right:10px;
      }
  }