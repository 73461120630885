/* Reset stylów */
.sf-cons-overlay,
.sf-cons-overlay .sf-cons,
.sf-cons-overlay .sf-switchcontainer,
.sf-cons-overlay .sf-switchslider {
  all: unset;
}

/* Styl dla głównego overlay */
.sf-cons-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  flex-wrap: wrap;
  gap: 1rem;
}

/* Styl dla głównej treści okna */
.sf-cons-overlay .sf-cons {
  background: white;
  color: #000;
  padding: 30px;
  border-radius: 8px;
  max-width: 700px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  margin: 20px;
  display: block;
  max-height: 80vh;
  overflow-y: auto;
  font-size: 1em;
  line-height: 1.6; /* Zwiększa odstęp między liniami tekstu */
  word-break: break-word; /* Zapewnia łamanie długich słów */
  z-index: 9999;
  border: 2px solid #4CAF50;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.4);
}

/* Blokowanie przewijania strony */
body.sf-lockscroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* Ukrywanie elementów */
.sf-cons-overlay .sf-closed {
  display: none !important;
  visibility: hidden !important;
}

/* Kontener dla przełączników */
.sf-cons-overlay .sf-switchcontainer {
  margin-top: 1rem;
  margin-bottom: 20px; /* Odstęp między przełącznikami */
  padding: 10px;
  width: 100%;
  line-height: 1.5;
}
.sf-text{
  background-color: #f9f9f9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
/* Nagłówki w kontenerze przełączników */
.sf-cons-overlay .sf-switchcontainer strong {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1em;
}

/* Styl dla przełącznika */
.sf-cons-overlay .sf-checkbox {
  display: none;
}

.sf-cons-overlay .sf-switch {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 40px;
  height: 20px;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 10px;
}

/* Styl suwaka przełącznika */
.sf-cons-overlay .sf-switchslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FA3B46;
  transition: .4s;
  border-radius: 34px;
}

.sf-cons-overlay .sf-switchslider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

/* Styl dla zaznaczonego przełącznika */
.sf-cons-overlay .sf-checkbox:checked + .sf-switchslider {
  background-color: #4CAF50;
}

.sf-cons-overlay .sf-checkbox:checked + .sf-switchslider:before {
  transform: translateX(18px);
}

/* Styl dla przełącznika wyłączonego */
.sf-cons-overlay .sf-switch.sf-disabled .sf-switchslider {
  background-color: #777;
}

.sf-cons-overlay .sf-disabled .sf-switchslider:hover {
  cursor: not-allowed !important;
}

/* Przycisk zamknięcia i ustawień */
.sf-cons-overlay #sfCloseDetailsButton,
.sf-cons-overlay #sfDetailsButton {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sf-cons-overlay #sfDetailsButton:hover {
  background-color: #45a049;
}

.sf-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Odstęp między przyciskami */
  margin-top: 20px;
}

#sfCloseDetailsButton,
#sfAgreeAllButton {
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 120px; /* Ustal minimalną szerokość, aby przyciski były tej samej wielkości */
}

/* Responsywność */
@media (max-width: 1199px) {
  .sf-cons-overlay .sf-cons {
    font-size: 1.1em;
    padding: 20px;
    max-width: 90%;
    margin: 10px;
  }

  .sf-cons-overlay .sf-switch {
    width: 32px;
    height: 14px;
  }

  .sf-cons-overlay .sf-switchslider:before {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 600px) {
  .sf-cons-overlay .sf-cons {
    font-size: 0.9em;
    padding: 15px;
  }

  .sf-cons-overlay .sf-switchcontainer strong {
    font-size: 1em;
  }
}
