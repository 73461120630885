body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: #148dd3;
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.mobile-view {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-view ul {
  list-style: none;
  padding: 0;
}

.mobile-view li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.mobile-view a {
  text-decoration: none;
  color: #15244d;
}

.desktop-view {
  display: flex;
  gap: 45px;
  padding-right: 30px;
}

header {
  background-color: #fff;
  color: #15244d;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header.scroll {
  background-color: #fff;
  color: #15244d;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}


.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 45px;
  padding-right: 30px;
}

nav a {
  text-decoration: none;
  font-size: large;
  color: black;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
}

nav a::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: black;
  transition: width 0.3s ease;
}

nav a:hover::after {
  width: 100%;
}

#dlaczego-pir{
  text-align: left;
  min-height: 400px;
  margin-bottom: 150px;
  position: relative;
  width: 100%;
  max-width: auto;
  padding: 10px;
  background-color: white;
}
section#about {
  text-align: left;
  min-height: 400px;
  margin-bottom: 150px;
  position: relative;
  width: 100%;
  max-width: auto;
  padding: 10px;
  background-color: white;
}
#buttons-plyty {
  display: flex;        
  justify-content: center; 
  gap: 20px;            
  padding: 10px;      
}

#about h1 {
  font-size: 40px;
}
#about h2{
  color: #0a4f70 ;
}
#about p {
  font-size: 20px;
}

.about-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
.about-padding{
  padding-bottom: 100px;
}

#text {
  width: 60%;
}

#banner {
  width: 40%;
}

#banner img {
  max-width: 100%;
}

.logo img {
  width: 200px;
  height: 100px;
}

main {
  flex: 1;
  background-color: white;
  margin: 150px;
  padding: 50px;
}

section {
  margin-bottom: 40px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 10px;
  padding-right: 40px;
  background-color: #0b74af;
  height: 120px;
}


.footer-content .logo img {
  padding-left: 10px;
}

body::after {
  content: "";
  flex: 1;
}

/* Contact */
#contact {
  width: auto;
  margin: 50 auto;
  padding: 20px;
}

#contact-text {
  margin-bottom: 40px;
  text-align: center;
}

#contact h2 {
  text-align: center;
}

#contact-wrapper {
  display: flex; 
  justify-content: center;
  gap: 20px;
}

#contact-info {
  background-color: #f5f5f5;
  padding: 20px;
  width: 90%;
  max-width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

#contact-content {
  margin: 0;
  padding: 0;
}

#contact-content a {
  color: black;
  text-decoration: none;
}

#contact-content a:hover {
  color: #0b74af;
}

#contact-form {
  flex: 1; 
  max-width: 450px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}


label {
  display: block;
  margin-bottom: 10px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  max-height: 200px;
}

button {
  background-color: #0b74af;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #095c86;
}

span {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

/* Contact end */


/*Card */
.card {
  width: calc(20%);
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.card-info {
  padding: 16px;
}

.card h3 {
  margin-bottom: 8px;
  font-size: 1rem;
}

.card p {
  font-size: 0.9rem;
  color: #555;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/*Card End*/
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0b74af;
  color: #fff;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-to-top.visible {
  opacity: 1;
}

.b-style {
  color: #070d1792;
}

#welcome-text{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #0a4f70 ; 
}

#Produkty{
  z-index: 99;
}


/*Opinie klientow*/
.opinie-klientow {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.opinie-klientow h2 {
  font-size: 24px;
  color: #333;
}

.opinie-klientow p {
  font-size: 16px;
  color: #555;
  margin: 10px 0;
}

.opinia {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.client-pic {
  width: 70px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.details {
  text-align: left;
}

.autor {
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.review {
  color: #ffd700;
  font-size: 18px;
  margin-top: 5px;
}

/*section faq*/
.faq-section {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.faq-section h2 {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #0056b3;
}

.faq-answer {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 16px;
  color: #333;
}


/*buttons */
.p-button{
  font-size: medium;
  width: 150px;
  height: 100px;
}
#home-map{
  margin-top: -150px;
}